import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons"
import { Link } from "gatsby"
import { useDilemmaApi, useRandomNextCard } from "../hooks/dilemmas"

import Layout from "../components/layout"
import Card from "./card"

const DefaultPicker = ({ pageContext, path }) => {
  const { pair: { left: leftText, right: rightText }, slug } = pageContext
  const [nextCard] = useRandomNextCard(slug)
  const [hasData, pairData, error, update] = useDilemmaApi(slug)
  const url = `https://developerdilemmas.com${path}`

  var leftPercentage = 0, rightPercentage = 0

  if (hasData && !error) {
    const leftCount = pairData.pair.left
    const rightCount = pairData.pair.right
    const totalCount = leftCount + rightCount

    leftPercentage = totalCount === 0 ? 0 : Math.floor(leftCount / totalCount * 100)
    rightPercentage = 100 - leftPercentage
  }

  const [mode, setMode] = useState("default")

  const [leftCard, setLeftCard] = useState({
    style: {
      transform: 'rotate(-' + (Math.floor(Math.random()*4)+2) + 'deg)',
    },
    classNames: "lg:mx-12 ",
    selected: false
  })

  const [rightCard, setRightCard] = useState({
    style: {
      transform: 'rotate(' + (Math.floor(Math.random()*4)+2) + 'deg)',
    },
    classNames: "lg:mx-12 ",
    selected: false,
  })

  function handleClick(left_or_right) {
    update(left_or_right)

    setMode("result")

    setLeftCard({
      ...leftCard,
      style: {
        transform: 'rotate(-1deg)',
      },
      classNames: "-mb-6 -ml-4 lg:-mx-12 lg:mt-0 ",
      selected: (left_or_right === "left") ? true : false
    })

    setRightCard({
      ...rightCard,
      style: {
        transform: 'rotate(1deg)',
      },
      classNames: "-mt-6 -mr-4 lg:-mx-12 lg:mt-8 ",
      selected: (left_or_right === "right") ? true : false
    })
  }

  return (
    <Layout>
      <div className="container mx-auto">
        <div className="relative lg:mx-auto py-12 md:py-20 lg:py-24 px-8 text-center ">
          <h1 className="text-xl md:text-2xl lg:text-3xl font-mono font-semibold">Pick one...</h1>
          <div className="flex flex-col lg:flex-row justify-center items-center my-8 md:my-12 lg:mt-20 lg:mb-20">
            <Card
              text={leftText}
              percentage={leftPercentage}
              style={leftCard.style}
              mode={mode}
              selected={leftCard.selected}
              classNames={leftCard.classNames + "bg-yellow "}
              onClick={handleClick.bind(this, "left")}
            />
          <div className="font-mono text-2xl lg:mb-8">or</div>
            <Card
              text={rightText}
              percentage={rightPercentage}
              style={rightCard.style}
              mode={mode}
              selected={rightCard.selected}
              classNames={rightCard.classNames + "bg-red "}
              onClick={handleClick.bind(this, "right")}
            />
          </div>
          { mode === "result" &&
            <div className="">
              <a href={`https://twitter.com/intent/tweet/?text=${leftCard.selected ? "✅" : "❌"} ${leftText} (${leftPercentage}%25).%0a${rightCard.selected ? "✅" : "❌"} ${rightText} (${rightPercentage}%25)%0a@AppSignal &url=${url}`}
                target="_blank"
                rel="noopener noreferrer"
                className="c-button c-button--gray c-button--lg m-4"
              >
                <FontAwesomeIcon icon={faTwitter} className="text-sm mr-2"  />
                Tweet results
              </a>
              {nextCard ?
                <Link to={nextCard.path} className="c-button c-button--gray c-button--lg m-4">
                  <FontAwesomeIcon icon={faArrowRight} className="text-sm mr-2"  />
                  Next dilemma
                </Link>
                :
                <Link to="/game-over/" className="c-button c-button--gray c-button--lg m-4">
                  <FontAwesomeIcon icon={faArrowRight} className="text-sm mr-2" />
                  Game over
                </Link>
              }
            </div>
          }
        </div>
      </div>
    </Layout>
  )
}

export default DefaultPicker
