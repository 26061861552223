import React, { useState, useEffect } from "react"
import CountUp from "react-countup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/pro-solid-svg-icons"
import Tilt from "react-tilt"

const Card = ({ text, percentage, style, mode, classNames, onClick, selected }) => {
  const [windowSizeWidth, setwindowSizeWidth] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      setwindowSizeWidth(window.innerWidth);
    }
    handleResize()
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [])

  function renderContent() {
    switch (mode) {
      case "result":
        return (
          <div>
            { selected &&
              <div className="absolute left-0 top-0 w-8 h-8 ml-4 mt-3">
                <div className="relative h-full w-full flex items-center justify-center">
                  <div className="absolute w-full h-full rounded-full bg-black opacity-25"></div>
                  <FontAwesomeIcon icon={faCheck} className="relative" />
                </div>
              </div>
            }
            <div className="font-mono text-3xl md:text-4xl mb-2 leading-tight font-semibold">
              <CountUp end={percentage}/>%
            </div>
            <div className=" text-lg">{ text }</div>
          </div>
        )
      default:
        return (
          <div className=" leading-tight text-xl md:text-2xl lg:text-3xl font-semibold">
            { text }
          </div>
        )
    }
  }

  return (
    <Tilt key={windowSizeWidth} options={{ scale: (windowSizeWidth > 640 ? 1.05 : 1.0), max: (windowSizeWidth > 640 ? 20 : 0) }} className={`w-full md:w-auto ${selected ? "z-10 " : ""}`}>
      <div
        className={"w-full h-32 md:h-40 lg:w-96 lg:h-64 my-4 md:my-10 lg:my-12 px-12 relative flex justify-center items-center cursor-pointer shadow-lg rounded-lg transition-all duration-500 " + classNames }
        style={style}
        onClick={mode !== "result" ? onClick : null}
      >
        { renderContent() }
      </div>
    </Tilt>
  )
}

export default Card
